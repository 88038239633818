import React from 'react';
import { Link } from 'react-router-dom';
import { FaXTwitter } from 'react-icons/fa6';
import { Container, Navbar } from 'react-bootstrap';

const DefaultLayout = ({ children }) => {
  return (
    <div className="d-flex flex-column min-vh-100">
      <Navbar bg="white" expand="lg" className="shadow-sm ">
        <Container>
          <Navbar.Brand>
            <Link to="/" className="navbar-brand d-flex align-items-center">
              <img src="/logo.png" alt="Logo" width="50" height="50" className="me-2 rounded-circle shadow" />
              {/* <span className="h5 mb-0">Gistify</span> */}
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end py-4 text-center">
            <a href="https://x.com/gistify_xyz" className="nav-link text-primary" target="_blank" rel="noopener noreferrer">
              <div className="d-flex align-items-center text-black">
                Follow us on <FaXTwitter className="ms-2 text-black" />
              </div>
            </a>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <main className="flex-grow-1">{children}</main>

      <footer className="bg-light text-center py-4 mt-auto">
        <div className="container">
          <p className="text-muted mt-3 mb-0">© 2024 3oh Inc. All Rights Reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default DefaultLayout;
