import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css'; // Your custom CSS (if needed)

// // Import Bootswatch or Bootstrap CSS
// import "bootswatch/dist/materia/bootstrap.min.css"; // Example: Slate theme from Bootswatch
// Alternatively, for standard Bootstrap:
import "bootstrap/dist/css/bootstrap.min.css";

import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
