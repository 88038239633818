import React, { useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import ReactMarkdown from 'react-markdown';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useColor } from 'color-thief-react';

const Project = () => {
  const { channelId } = useParams(); // Get the channelId from the URL
  const [channelName, setChannelName] = useState('');
  const [gist, setGist] = useState('');
  const [countdown, setCountdown] = useState({ hours: 0, minutes: 0, seconds: 0 });
  const [isLoading, setIsLoading] = useState(true);
  const [textColor, setTextColor] = useState('#fff'); // Default text color is white

  const imageSrc = `/images/${channelId}.jpg`;

  // Extract the dominant color using color-thief-react
  const { data: dominantColor } = useColor(imageSrc, 'rgbArray', { crossOrigin: 'anonymous' });

  useEffect(() => {
    if (dominantColor) {
      // Calculate the luminance of the background color
      const [r, g, b] = dominantColor;
      const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

      // If the luminance is low (dark background), set text color to white
      // Otherwise, set text color to black
      setTextColor(luminance > 0.5 ? '#000' : '#fff');
    }
  }, [dominantColor]);

  useEffect(() => {
    if (!channelId) {
      console.error('No channelId in URL.');
      return;
    }

    // Connect to the backend server
    const socket = io(process.env.REACT_APP_BACKEND_URL, {
      query: { channelId }, // Pass the channelId as a query parameter
    });

    // Listen for the channel name
    socket.on('channelName', (name) => {
      console.log('Received channel name:', name);
      setChannelName(name);
    });

    // Listen for emitted updates from the backend
    socket.on('cronUpdate', (data) => {
      console.log('Received cron update:', data);
      setGist(data);
      setIsLoading(false);
    });

    // Listen for countdown updates
    socket.on('countdown', (data) => {
      setCountdown(data);
    });

    // Cleanup on component unmount
    return () => {
      socket.disconnect();
    };
  }, [channelId]);

  const { summary, updatedAt } = gist;

  return (
    <div className="container-fluid p-0">
      {/* Hero Section */}
      <Container
        fluid
        className="text-center py-5 d-flex align-items-center"
        style={{
          ...styles.heroSection,
          background: dominantColor ? `rgb(${dominantColor.join(',')})` : 'linear-gradient(135deg, #4e54c8, #8f94fb)', // Fallback gradient
          color: textColor, // Dynamically set text color
        }}
      >
        <Row className="w-100">
          <Col>
            <div style={styles.logoContainer}>
              <img src={imageSrc} alt="Channel Logo" style={styles.logo} crossOrigin="anonymous" />
            </div>

            <h1 className="display-5 fw-bold mb-3" style={{ color: textColor }}>
              {channelName ? channelName.replace(' - ADMINS WILL NOT DM', '') : isLoading ? 'Loading...' : 'No Channel Found'}
            </h1>
            {channelName && (
              <>
                <p className="lead mb-0" style={{ color: textColor }}>
                  <strong>Last Updated:</strong> {moment(updatedAt).format('MMM Do YYYY, h:mm:ss a')}
                </p>
                <p className="lead mb-0" style={{ color: textColor }}>
                  <strong>Next Update In:</strong> {countdown.hours}h {countdown.minutes}m {countdown.seconds}s
                </p>
              </>
            )}
          </Col>
        </Row>
      </Container>

      {channelName && !isLoading ? (
        <>
          {/* Main Content */}
          <div className="container fluid pb-5">
            {channelId == '1853605030' && (
              <div className="text-center mt-5">
                <Button target="_blank" rel="noopener noreferrer" href="https://t.me/getbasedai" style={styles.waitlistButton} className="mb-2">
                  Visit Telegram
                </Button>
              </div>
            )}
            {channelId == '1081478541' && (
              <div className="text-center mt-5">
                <Button target="_blank" rel="noopener noreferrer" href="https://t.me/pepecoins" style={styles.waitlistButton} className="mb-2">
                  Visit Telegram
                </Button>
              </div>
            )}
            {channelId == '1556054753' && (
              <div className="text-center mt-5">
                <Button target="_blank" rel="noopener noreferrer" href="https://t.me/watcherguru" style={styles.waitlistButton} className="mb-2">
                  Visit Telegram
                </Button>
              </div>
            )}

            <div>
              <div className="markdown-content">
                {summary && summary.length > 200 ? (
                  <div>
                    <ReactMarkdown>{summary}</ReactMarkdown>
                  </div>
                ) : (
                  <p className="text-muted text-center mb-0">No summaries available yet!</p>
                )}
              </div>
            </div>
            {/* Call-to-Action Section */}
            <div className="text-center mt-5">
              <Button
                target="_blank"
                rel="noopener noreferrer"
                href="https://docs.google.com/forms/d/e/1FAIpQLScbSBcVYL9WH3oTtLKHZQme4IlTUtTqX1DJ8S1dxbX-3pZz7Q/viewform"
                style={styles.waitlistButton}
                className="mb-2"
              >
                Join the Waitlist
              </Button>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

const styles = {
  waitlistButton: {
    fontSize: '1.5rem', // Larger text
    padding: '1rem 2rem', // Bigger button
    fontWeight: '600', // Bolder font
    borderRadius: '8px', // Slightly rounded corners
    backgroundColor: '#4e54c8', // Custom background color
    color: '#fff', // Button text color
    border: 'none', // Remove border
    transition: 'all 0.3s ease', // Smooth hover transition
  },
  waitlistButtonHover: {
    backgroundColor: '#3c42b3', // Darker shade on hover
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', // Add subtle shadow
  },
  heroSection: {
    color: '#fff',
    transition: 'background 0.5s ease', // Smooth transition for the background
  },
  logoContainer: {
    width: '10rem',
    height: '10rem',
    margin: '0 auto',
    borderRadius: '50%',
    background: '#fff',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '1rem',
  },
  logo: {
    width: '80%',
    height: 'auto',
    borderRadius: '50%',
  },
};

export default Project;
