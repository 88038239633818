import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Gist from './pages/gist';
import Home from './pages/home';
import './App.css';
import './markdown.css';
import DefaultLayout from './layouts/defaultLayout';

const App = () => {
    return (
        <Router>
            <DefaultLayout>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/:channelId" element={<Gist />} />
                </Routes>
            </DefaultLayout>
        </Router>
    );
};

export default App;
