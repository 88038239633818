import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';

const Home = () => {
  return (
    <div>
      {/* Hero Section */}
      <Container fluid className="text-center py-5 d-flex align-items-center" style={styles.heroSection}>
        <Row className="w-100">
          <Col>
            <div style={styles.logoContainer}>
              <img src="/logo.png" alt="Gistify Logo" style={styles.logo} />
            </div>
            <h1 className="display-4 fw-bold mb-3 text-light">Welcome to Gistify</h1>
            <p className="lead mb-4 text-white">Stay effortlessly updated with AI-powered summaries from Telegram channels. Discord and X integrations are on the way!</p>
            <Button
              target="_blank"
              rel="noopener noreferrer"
              href="https://docs.google.com/forms/d/e/1FAIpQLScbSBcVYL9WH3oTtLKHZQme4IlTUtTqX1DJ8S1dxbX-3pZz7Q/viewform"
              style={styles.waitlistButton}
              className="mb-2"
            >
              Join the Waitlist
            </Button>
          </Col>
        </Row>
      </Container>

      {/* Featured Channels Section */}

      <Container
        className="p-4 py-5"
        style={{
          ...styles.featuredSection,
          // backgroundImage: "url('/images/bg.jpg')",
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          // backgroundAttachment: 'fixed',
          position: 'relative',
        }}
      >
        {/* Overlay for better text contrast */}
        {/* <div style={styles.overlay}></div> */}
        <Row className="text-center mb-4" style={styles.featuredTextContainer}>
          <Col>
            <h2 className="display-6 mb-3" style={styles.featuredTitle}>
              Curated Channels
            </h2>
            <p className="lead" style={styles.featuredSubtitle}>
              Explore some of curated channels to see how Gistify can help you stay updated with the latest news and trends.
            </p>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={4} sm={4} md={3} lg={2} className="text-center mb-4">
            <a href="/1081478541">
              <div style={styles.channelCard}>
                <img src="/images/1081478541.jpg" alt="Pepe" style={styles.channelImage} />
              </div>
            </a>
          </Col>
          <Col xs={4} sm={4} md={3} lg={2} className="text-center mb-4">
            <a href="/1853605030">
              <div style={styles.channelCard}>
                <img src="/images/1853605030.jpg" alt="Based" style={styles.channelImage} />
              </div>
            </a>
          </Col>
          <Col xs={4} sm={4} md={3} lg={2} className="text-center mb-4">
            <a href="/1556054753">
              <div style={styles.channelCard}>
                <img src="/images/1556054753.jpg" alt="Watcher" style={styles.channelImage} />
              </div>
            </a>
          </Col>
        </Row>
      </Container>

      {/* Features Section */}
      <div style={styles.featureListContainer}>
        <Container className="py-5">
          <Row className="text-center mb-4">
            <Col>
              <h2 className="display-6 mb-3" style={styles.featuredTitle}>
                Features
              </h2>
              <p className="lead" style={styles.featuredSubtitle}>
                We'll be rolling out more features soon, but here's a sneak peek at what Gistify will offer at launch.
              </p>
            </Col>
          </Row>
          <hr className="mb-4" />
          <Row className="align-items-center mb-4">
            <Col md={6}>
              <div style={styles.featureItemLeft}>
                <span style={styles.customBullet}>⚡</span>
                <div>
                  <h5 className="text-primary">AI-Powered Summaries</h5>
                  <p className="text-muted">Automatically generate concise and meaningful updates from your favorite channels using AI technology.</p>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div style={styles.featureItemRight}>
                <span style={styles.customBullet}>✨</span>
                <div>
                  <h5 className="text-primary">Smarter Insights</h5>
                  <p className="text-muted">
                    Gistify doesn’t just summarize—it highlights trends and key points, giving you a deeper understanding of what’s happening in your communities.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          <hr className="mb-4" />
          <Row className="align-items-center mb-4">
            <Col md={6}>
              <div style={styles.featureItemLeft}>
                <span style={styles.customBullet}>🔗</span>
                <div>
                  <h5 className="text-primary">Multi-Platform Integration</h5>
                  <p className="text-muted">Connect your Telegram channels at launch, with Discord and X integrations coming soon for seamless updates across platforms.</p>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div style={styles.featureItemRight}>
                <span style={styles.customBullet}>😊</span>
                <div>
                  <h5 className="text-primary">Sentimentometer (Coming Soon)</h5>
                  <p className="text-muted">
                    Quickly gauge the mood of your communities with our upcoming sentiment analysis tool, visualized with intuitive emoji-based feedback.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          <hr className="mb-4" />
          <Row className="align-items-center">
            <Col md={6}>
              <div style={styles.featureItemLeft}>
                <span style={styles.customBullet}>🔊</span>
                <div>
                  <h5 className="text-primary">Cut Out the Noise</h5>
                  <p className="text-muted">Filter out unnecessary chatter and focus on the updates that matter most, freeing you to make smarter decisions faster.</p>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div style={styles.featureItemRight}>
                <span style={styles.customBullet}>🔄</span>
                <div>
                  <h5 className="text-primary">Seamless App Handover (Coming Soon)</h5>
                  <p className="text-muted">
                    Jump straight from Gistify's highlights into the relevant conversation threads in the originating app. Stay connected without missing a beat.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Call-to-Action Section */}
      <Container fluid className="text-center py-5" style={styles.ctaSection}>
        <Row>
          <Col>
            <h2 className="display-6 mb-3 text-white">Get the Gist?</h2>
            <p className="lead mb-4 text-light">Simplify your communication workflow and never miss important updates. Gistify makes staying in the loop easier than ever.</p>
            <Button
              href="https://docs.google.com/forms/d/e/1FAIpQLScbSBcVYL9WH3oTtLKHZQme4IlTUtTqX1DJ8S1dxbX-3pZz7Q/viewform"
              target="_blank"
              rel="noopener noreferrer"
              variant="primary"
              size="lg"
              className="me-3"
              style={styles.ctaWaitlistButton}
            >
              Join the Waitlist
            </Button>
            <Button href="https://x.com/gistify_xyz" target="_blank" rel="noopener noreferrer" variant="outline-light" size="lg">
              Follow us on X
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const styles = {
  waitlistButton: {
    fontSize: '1.5rem', // Larger text
    padding: '1rem 2rem', // Bigger button
    fontWeight: '600', // Bolder font
    borderRadius: '8px', // Slightly rounded corners
    backgroundColor: '#4e54c8', // Custom background color
    color: '#fff', // Button text color
    border: 'none', // Remove border
    transition: 'all 0.3s ease', // Smooth hover transition
  },
  waitlistButtonHover: {
    backgroundColor: '#3c42b3', // Darker shade on hover
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', // Add subtle shadow
  },
  heroSection: {
    background: 'linear-gradient(135deg, #4e54c8, #8f94fb)',
    color: '#fff',
  },
  logoContainer: {
    width: '10rem',
    height: '10rem',
    margin: '0 auto',
    borderRadius: '50%',
    background: '#fff',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '1rem',
  },
  logo: {
    width: '100%',
    height: 'auto',
    borderRadius: '50%',
  },
  featuredSection: {
    position: 'relative',
    padding: '3rem 0',
    color: '#fff', // Ensure text is white to contrast with background
  },
  featuredTextContainer: {
    position: 'relative', // Position above the overlay
    zIndex: 2,
  },
  featuredTitle: {
    color: '#333',
    fontWeight: '400',
    fontSize: '2.2rem',
  },
  featuredSubtitle: {
    color: '#333',
  },
  channelCard: {
    background: 'rgba(255, 255, 255, 0.8)', // Subtle overlay on cards
    // borderRadius: '12px',
    // padding: '1rem',
    // boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    position: 'relative',
    zIndex: 2,
  },
  channelImage: {
    width: '90%',
    height: 'auto',
    borderRadius: '50%',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    // marginBottom: '1rem',
  },
  channelName: {
    fontSize: '1rem',
    fontWeight: '500',
    color: '#333',
    marginBottom: 0,
  },
  featureCard: {
    padding: '2rem',
    background: '#fff',
    borderRadius: '8px',
    // boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  },
  featureImage: {
    width: '120px',
    height: '120px',
  },
  ctaSection: {
    background: 'linear-gradient(135deg, #4e54c8, #8f94fb)',
  },
  ctaWaitlistButton: {
    backgroundColor: '#4e54c8', // Custom background color
    color: '#fff', // Button text color
    border: '1px solid #4e54c8',
    transition: 'all 0.3s ease', // Smooth hover transition
  },
  featureListContainer: {
    background: '#f7f7f7',
  },
  featureList: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
  },
  featureItemLeft: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: '1rem',
    textAlign: 'left',
  },
  featureItemRight: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: '1rem',
    textAlign: 'left',
  },
  customBullet: {
    fontSize: '1.8rem',
    color: '#4e54c8',
    marginRight: '1rem',
    flexShrink: 0,
  },
};

export default Home;
